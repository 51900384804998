<template>
  <div class="overflow-y-auto overflow-x-hidden">
    <my-tool-bar
      :loading="loading"
      :action-mode="actionMode"
      :title="title"
    >
      <template #action>
        <toolbar-modify-action
          @close="tryToCloseEditMode"
          @save="onSave"
        />
      </template>
      <template #buttons>
        <!--검색-->
        <v-btn
          icon
          @click="onModifyModeClicked"
        >
          <!--<v-icon>mdi-file-document-edit</v-icon>-->
          <img src="@/assets/images/main/svg/button_Edit.svg">
        </v-btn>
      </template>
    </my-tool-bar>

    <div style="position: relative; top: 56px; height: calc(100% - 56px);">
      <template v-if="!!tnDeviceId">
        <template v-if="!loading">
          <m-form
            ref="form"
            :edit.sync="editMode"
            :form-data.sync="formData"
            :form-info="formInfo"
            class="overflow-y-auto"
            form-provider="mps"
          />
        </template>
        <template v-else>
          <progress-loading />
        </template>
      </template>
      <template v-else>
        <invalid-data />
      </template>
    </div>
  </div>
</template>

<script>
    import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import MForm from "@/assets/plugins/mps-form/MForm";
    import {MAXLENGTH, PATH} from "@/constants";
    import ToolbarModifyAction from "@/components/app/toolbar/ToolbarModifyAction";
    import VuexProps from "@/framework/vuex/VuexProps";
    import InvalidData from "@/components/part/InvalidData";
    import ProgressLoading from "@/components/part/ProgressLoading";
    import GroupHeader from "@/assets/plugins/mps-form/providers/components/group/GroupHeader";
    import BackGuardEditModeInterface from "@/components/windows/mixins/BackGuardEditModeInterface";

    export default {
        name: "DeviceDetail",
        components: {ProgressLoading, InvalidData, ToolbarModifyAction, MForm, MyToolBar},
        extends: WindowComponent,
        mixins: [BackGuardEditModeInterface],
        props: {
            tnDeviceId: undefined,
            accountId: undefined,
        },
        data() {
            return {
                alreadyLoaded: false,
                loading: false,
                updating: false,
                actionMode: false,
                formData: undefined,
                formInfo: [
                    {
                        component: GroupHeader,
                        propsData: {
                            title: this.$translate("장치 정보"),
                        },
                    },
                    {
                        mutable: false,
                        title: this.$translate('거래처명'),
                        key: 'accountName',
                        read: (inEditMode, data) => !!data.accountId ? data.accountName + "[" + data.accountId + "]" : this.$translate("본사"),
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {},
                    },
                    {
                        mutable: false,
                        title: this.$translate('시리얼번호'),
                        key: 'serialNo',
                        read: (inEditMode, data) => this.$serial(data.serialNo),
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                    },
                    {
                        mutable: true,
                        title: this.$translate('설치위치'),
                        key: 'installLocation',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                            maxlength: MAXLENGTH.DEVICE.INSTALL_LOCATION,
                        },
                        fullWidthLabel: true,
                    },
                    {
                        mutable: false,
                        title: this.$translate('접속상태'),
                        key: 'status',
                        read: (inEditMode, data) => this.statusName(data.status),
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {},
                    },
                    {
                        mutable: false,
                        title: 'IP',
                        key: 'ip',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {},
                    },
                    {
                        mutable: false,
                        title: this.$translate('장치구분'),
                        key: 'deviceType',
                        read: (inEditMode, data) => this.deviceTypeName(data.deviceType),
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {},
                    },
                    {
                        mutable: false,
                        title: this.$translate('장치버전'),
                        key: 'firmwareVersion',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {},
                    },
                    {
                        mutable: false,
                        title: this.$translate('수집일자'),
                        key: 'uptDatetime',
                        formReadType: this.$mps.form.types.DATETIME_PICKER,
                        formWriteType: this.$mps.form.types.DATETIME_PICKER,
                        options: {},
                    },
                    {
                        mutable: true,
                        title: this.$translate('비고'),
                        key: 'memo',
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                            maxlength: MAXLENGTH.DEVICE.MEMO,
                        },
                        fullWidthLabel: true,
                    },
                ],
            }
        },
        mounted() {
            this.loadDetail();
        },
        methods: {
            deviceTypeName(deviceType) {
                if (deviceType === "PC") {
                    return "PC에이전트";
                } else if (deviceType === "DV") {
                    return "콘솔";
                }

                return ""
            },

            statusName(status) {
                if (status === "1") {
                    return "접속중";
                }

                return "연결끊어짐"
            },

            async loadDetail(loading = true) {
                this.loading = loading;
                try {
                    const response = await this.$request(PATH.DEVICE.GET)
                        .setObject({
                            tnDeviceId: this.tnDeviceId,
                            accountId: this.accountId,
                        })
                        .wait(500)
                        .catch()
                        .enqueue()
                        .catch(error => {
                          if(error.response.data.code === 0) {
                            this.$snackbarError(this.$translate("입고된 장치입니다."));
                          }
                          this.routerManager.back()
                        })
                    this.formData = response;
                } catch (e) {
                  this.$alert(this.$translate("장치 로딩중 에러가 발생했습니다."))
                  this.routerManager.back()
                }
                this.loading = false;
            },

            async updateDetail() {
                this.updating = true;
                try {
                    const response = await this.$requestWithOverlay(PATH.DEVICE.UPDATE)
                        .setObject(this.formData)
                        .enqueue();
                    this.setEditMode(false);
                    this.$snackbar(this.$translate("장치 정보가 수정되었습니다."));
                    this.backGuard.pop(); // 업데이트에 성공하였다면 편집모드에서 설정된 백가드를 제거합니다.
                } catch (e) {
                    this.$refs.form.restore();
                }
                this.updating = false;
            },

            onModifyModeClicked() {
                this.setEditMode(true);
            },

            onSave() {
                try {
                    this.updateDetail();
                } catch (e) {
                    // 오류발생
                }
            },

            setEditMode(enabled) {
                this.actionMode = enabled;
                this.editMode = enabled;
            },
        },
    }
</script>

<style scoped>

</style>
